import { Fragment, React } from "react";

const Resources = () => {
    return (
        <Fragment>
            <section id="resources">
                <div className="row">
                    <div className="twelve columns main-col">
                        <h2>Resources</h2>
                        <br />
                        <h5>Safety & Loss Control Services</h5>
                        <div style={{ textAlign: 'justify' }}>
                            <p style={{ textAlign: 'justify' }}>
                                To support our transportation customers, Complete Claim Consulting, LLC conducts on-site safety and loss control training utilizing experienced transportation staff. Our goal is to be part of your safety management team. We provide services on the following topics:
                        </p>
                            <ul style={{ color: '#122D4F', fontWeight: 'bold', marginLeft: 50, display: 'list-item', listStyleType: 'disc' }}>
                                <li>
                                    Individual Company Management and Fleet Survey
                            </li>
                                <li>
                                    Workers' Compensation Fraud Education Program
                            </li>
                                <li>
                                    Claims Reviews of All Claims
                            </li>
                                <li>
                                    Back to Work & Rehabilitation for Injured Workers
                            </li>
                                <li>
                                    Consulting and Preparation for Insurance Co. Inspections
                            </li>
                                <li>
                                    Agent Safety Manual
                            </li>
                                <li>
                                    DOT / OSHA Compliance
                            </li>
                            </ul>
                            <p>
                                As a special advantage for our customers is Complete Claim Consulting, LLC has developed a new online virtual library of safety materials.
                                This system provides our customers with a solution to pro-actively reduce claims by educating their employees and providing them with tools
                                to access accurate and relevant safety materials.
                        </p>
                            <p>
                                Complete Claim Consulting, LLC Safety Portal also includes:
                        </p>
                            <ul style={{ color: '#122D4F', fontWeight: 'bold', marginLeft: 50, display: 'list-item', listStyleType: 'disc' }}>
                                <li>
                                    RSS feeds of popular safety resources
                            </li>
                                <li>
                                    Up to date industry news
                            </li>
                                <li>
                                    Applicable quick links and hot topics
                            </li>
                            </ul>
                            <p>
                                We invite you to register at <a href="http://cccclaims/safetyportal.com/">http://cccclaims/safetyportal.com/</a>
                            </p>
                        </div>
                    </div>
                </div>

            </section>
        </Fragment >
    );
}

export default Resources;