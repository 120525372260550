import { Card, CardContent, Grid, makeStyles, Typography } from "@material-ui/core";
import { Fragment, React } from "react";
import { FadeInSection } from "./Helpers/FadeinSection";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        color: '#838C95',
        padding: 10,
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
        fontSize: 15,
        textAlign: 'left'
    },
    coveragesBackground: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '50% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    coveragesBackgroundMobil: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '100% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
});

const Careers = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <section id="careers">
                <h2>Careers</h2>
                <Grid container>
                    {/* <Grid item xs={12} lg="6" style={{ padding: 20 }}>
                        <Card>
                            <CardMedia className={classes.media} />
                        </Card>
                    </Grid> */}
                    <Grid item xs={12} md={12} lg={12} style={{ padding: 20 }}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography className={classes.pos} color="textSecondary">
                                    <FadeInSection>
                                        {/* <Grid item xs={12} lg={12} style={{ padding: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Card style={{ width: '50%' }}>
                                                <CardMedia className={classes.media} image={claims} />
                                            </Card>
                                        </Grid> */}
                                        <p>
                                            STERN is an Equal Opportunity Employer that is committed to inclusion and diversity. We are devoted to finding diverse individuals who are committed to the success of our clients and our organization.
                                    </p>
                                        <br /><br /><br /><br /><br /><br /><br /><br />
                                    </FadeInSection>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </section>
        </Fragment>
    );
}

export default Careers;