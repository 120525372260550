import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../../Style/style2.css';
// import trailer3 from "../../Img/carousel5.png";
// import trailer4 from "../../Img/4.jpg";
// import trailer5 from "../../Img/5.png";
// import trailer6 from "../../Img/6.jpg";
// import trailer8 from "../../Img/8.jpg";
// import trailer7 from "../../Img/12.jpg";
// import trailer9 from "../../Img/9.jpg";
// import trailer10 from "../../Img/10.jpg";
// import trailer11 from "../../Img/11.jpg";
import GS_Liability from "../../Img/GS_Liability.jpg"
import SnadGravel from "../../Img/SnadGravel.jpg"
import LL from "../../Img/LL.jpg"
import Frac from "../../Img/Frac.jpg"
import CC from "../../Img/CC.jpg"
import { Fragment, useState } from 'react';


const CarouselComponent = () => {
    const [value, setValue] = useState(0);

    const onChange = value => {
        console.log(value)
        setValue(value);
    }
    return (
        <Fragment>
            <Carousel
                style={{ height: '100%', backgroundColor: 'black' }}
                onChange={onChange}
                showStatus={false}
                stopOnHover={false}
                autoPlay
                showThumbs={false}
                infiniteLoop
                interval={6000}
                transitionTime={1000}
            >
                <div >
                    <img style={{ height: '110%', objectFit: 'cover' }} src={GS_Liability} />
                </div>
                <div >
                    <img style={{ height: '110%', objectFit: 'cover' }} src={SnadGravel} />
                </div>
                <div >
                    <img style={{ height: '110%', objectFit: 'cover' }} src={LL} />
                </div>
                <div >
                    <img style={{ height: '110%', objectFit: 'cover' }} src={Frac} />
                </div>
                <div style={{ height: '100%' }}>
                    <img style={{ height: '100%', objectFit: 'cover' }} src={CC} />
                </div>
            </Carousel>
        </Fragment>
    )
};

export default CarouselComponent;