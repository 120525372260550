import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { Fragment, React } from "react";
import { FadeInSection } from "./Helpers/FadeinSection";
import getQuote from "../Img/getQuote.jpg";
import DescriptionIcon from "@material-ui/icons/Description";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    color: "#838C95",
    padding: 10,
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  pos: {
    marginBottom: 12,
    fontSize: 15,
  },
  coveragesBackground: {
    // backgroundImage: `url(${background}) !important`,
    // backgroundSize: '50% 100%',
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
  },
  coveragesBackgroundMobil: {
    // backgroundImage: `url(${background}) !important`,
    // backgroundSize: '100% 100%',
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  hover: {
    "&$hover:hover": {
      color: "black",
    },
  },
});

const GetQuote = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <section id="getquote">
        <h2>Get a quote</h2>
        <Grid container direction="row-reverse">
          <Grid item xs={12} md={6} lg={6} style={{ padding: 20 }}>
            <Card>
              <CardMedia className={classes.media} image={getQuote} />
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={6} style={{ padding: 20 }}>
            <Card className={classes.root}>
              <CardContent>
                <FadeInSection>
                  <div style={{ textAlign: "left" }}>
                    An easy to fill out quote request is available for you to
                    download here. Once completed, scan it or take a picture and
                    then email it to us at{" "}
                    <Button
                      className={classes.hover}
                      variant="outlined"
                      color="primary"
                      href="mailto:quotes@sternib.com"
                    >
                      quotes@sternib.com
                    </Button>
                  </div>
                  <br />
                  <p>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginRight: "50px" }}
                    >
                      <Link
                        style={{ color: "white" }}
                        to={process.env.PUBLIC_URL + "files/QQS.html"}
                        target="_blank"
                      >
                        <DesktopWindowsIcon />
                        &nbsp; Online <br /> Quick Quote
                      </Link>{" "}
                    </Button>
                    <Button variant="contained" color="primary">
                      <Link
                        style={{ color: "white" }}
                        to="/files/Quote Request Form.pdf"
                        target="_blank"
                        download
                      >
                        <DescriptionIcon />
                        &nbsp; Download Fillable <br /> Quick Quote
                      </Link>{" "}
                    </Button>
                  </p>
                </FadeInSection>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </section>
    </Fragment>
  );
};

export default GetQuote;
