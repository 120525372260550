import { Fragment } from "react";
import About from "../About";
import BecomeProducer from "../BecomeProducer";
import Careers from "../Careers";
import Claims from "../Claims";
import Contact from "../Contact";
import Coverages from "../Coverages";
import GetQuote from "../GetQuote";
import Markets from "../Markets";
import NavBar from "../NavBar";
import OfficeOfCompliance from "../OfficeOfCompliance";
import PreferedVendors from "../PreferedVendors";
import Resources from "../Resources";
import LossRun from "../LossRuns";
import Epay from "../Epay";
import NavBar1 from "../NavBar1"

const Main = () => {
    return (
        <Fragment>
            {/* <NavBar /> */}
            <NavBar1 />
            <Epay/>
            <Coverages />
            <Markets />
            <About />
            <GetQuote />
            <BecomeProducer />
            <Contact />
            <Claims />
            <OfficeOfCompliance />
            <Careers />
            <PreferedVendors />
            <Resources />
            <LossRun/>
        </Fragment>
    )
}
export default Main;