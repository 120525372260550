import { Avatar, Button, Card, CardActionArea, CardContent, CardHeader, Collapse, DialogContentText, Divider, Fab, Grid, IconButton, List, ListItem, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import { forwardRef, Fragment, React, useState } from "react";
import logo from "../Img/roundSternLogo.png";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PhoneIcon from '@material-ui/icons/Phone';
import CarouselComponent from "./Helpers/CarouselComponent";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CallIcon from '@material-ui/icons/Call';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import clsx from 'clsx';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import { Link } from "react-router-dom";
import Banner from "../Img/epay2.png";

const useStyles = makeStyles((theme) => ({
    nested: {
        padding: '10px 10px 10px 80px !important',
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    buttonQuote: {
        backgroundColor: '#f78320',
        fontWeight: 'bold',
        color: 'white !important',
        width: '185px !important',
        marginRight: '15px',
    },
    buttonsBap: {
        backgroundColor: '#f78320',
        fontWeight: 'bold',
        marginRight: '15px',
        color: 'white !important',
        width: '185px'
    },
    buttonContact: {
        backgroundColor: '#f78320',
        fontWeight: 'bold',
        color: 'white !important',
        width: '185px !important'
    }
}));
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const NavBar = () => {
    const classes = useStyles();
    const width = useMediaQuery('(min-width:770px)');
    const [open, setOpen] = useState(false);
    const [openAccountingDialog, setOpenAccountingDialog] = useState(false);
    const [currentSection, setCurrentSection] = useState('#coverages');



    const handleClick = () => {
        setOpen(!open);
    };

    const handleCloseCoverages = () => {
        setOpen(false)
    }

    const cardClick = (e) => {
        setOpenAccountingDialog(true)
    }

    const handleCloseAccountingDialog = () => {
        setOpenAccountingDialog(false)
    }

    const handleNextSectionClick = () => {
        let section = window.location.href;
        section = section.substring(section.indexOf('#'))
        console.log(section)
        switch (section) {
            case "":
            case "#home":
                setCurrentSection('#markets')
                break;
            case "#coverages":
                setCurrentSection('#about')
                break;
            case "#markets":
                setCurrentSection('#getquote')
                break;
            case "#about":
                setCurrentSection('#becomeproducer')
                break;
            case "#getquote":
                setCurrentSection('#contact')
                break;
            case "#becomeproducer":
                setCurrentSection('#claims')
                break;
            case "#contact":
                setCurrentSection('#officeofcompliance')
                break;
            case "#claims":
                setCurrentSection('#careers')
                break;
            case "#officeofcompliance":
                setCurrentSection('#resources')
                break;
            case "#careers":
                setCurrentSection('#home')
                break;
            case "#resources":
                setCurrentSection('#coverages')
                break;
            case "#lossruns":
                setCurrentSection('#lossruns')
                break;
            default:
                break;
        }

    };

    return (
        <Fragment>
            <header id="home">
                <nav id="nav-wrap">
                    <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                    <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>
                    <Grid container>
                      <Grid item lg={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white' }}>
                        
                        <ul id="nav" className="nav">
                        <li className="current"><a onMouseEnter={handleCloseCoverages} className="smoothscroll" href="#home">HOME</a></li>
                        <li>
                            <a className="smoothscroll" href="#coverages">PRODUCT LINES </a>

                        </li>
                        <li><a className="smoothscroll" href="#markets" onMouseEnter={handleCloseCoverages}>MARKETS</a></li>
                        <li><a className="smoothscroll" href="#about">ABOUT US</a></li>
                        <li><a className="smoothscroll" href="#getquote">GET QUOTE</a></li>
                        <li><a className="smoothscroll" href="#becomeproducer">BECOME PRODUCER</a></li>
                        <li><a className="smoothscroll" href="#contact">CONTACT US</a></li>
                        <li><a className="smoothscroll" href="#claims">CLAIMS</a></li>
                        <li><a className="smoothscroll" href="#officeofcompliance">COMPLIANCE</a></li>
                        <li><a className="smoothscroll" href="#careers">CAREERS</a></li>
                        <li><a className="smoothscroll" href="#preferedvendors">VENDORS</a></li>
                        <li><a className="smoothscroll" href="#resources">RESOURCES</a></li>
                        <li><a className="smoothscroll" href="#lossruns">LOSS RUNS</a></li>
                    </ul>
                    </Grid>
                    </Grid>
                    <Collapse in={open} timeout="auto" unmountOnExit style={{ backgroundColor: '#132d5a', color: 'white' }}>
                        <List component="div" disablePadding onMouseLeave={handleClick}>
                            <ListItem className={classes.nested}>
                                Commercial Auto liability
                            </ListItem>
                            <ListItem className={classes.nested}>
                                Collision coverage / Physical Damage
                            </ListItem>
                            <ListItem className={classes.nested}>
                                Uninsured and underinsured motorist coverage
                            </ListItem>
                            <ListItem className={classes.nested}>
                                Comprehensive / Physical Damage
                            </ListItem>
                            <ListItem className={classes.nested}>
                                Motor Truck Cargo (MTC)
                            </ListItem>
                            <ListItem className={classes.nested}>
                                Trailer Interchange
                            </ListItem>
                        </List>
                    </Collapse>
                </nav>

                {
                    !width ?
                        // ------- MOBILE
                        <Grid container style={{ float: 'left' }}>

                            <Grid item xs={12} lg={6} md={6} >
                                <div className="banner-text">
                                    <img alt="company logo" src={logo} width="40%"></img>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={6} md={6}>
                                <div style={{ textAlign: 'center' }}>
                                    <Fab
                                        variant="extended"
                                        size="medium"
                                        color="primary"
                                        aria-label="add"
                                        className={classes.buttonsBap}
                                        href="#becomeproducer"
                                    >
                                        <AssignmentIcon className={classes.extendedIcon} />
                                        Get Appointed
                                    </Fab>
                                    <Fab
                                        variant="extended"
                                        size="medium"
                                        color="primary"
                                        aria-label="add"
                                        className={classes.buttonQuote}
                                        href="#getquote"

                                    >
                                        <DescriptionIcon className={classes.extendedIcon} />
                                        Get a Quote
                                    </Fab>
                                    <Fab
                                        variant="extended"
                                        size="medium"
                                        color="primary"
                                        aria-label="add"
                                        className={classes.buttonContact}
                                        href="#contact"

                                    >
                                        <PhoneIcon className={classes.extendedIcon} />
                                        Contact us
                                    </Fab>
                                    <h2>
                                        Please call us (706) 395-4200
                                    </h2>
                                </div>


                                {/* <p style={{ marginTop: '10%', textAlign: "justify", lineHeight: '20px', padding: '20px' }}>
                                    GSIAY is an acting service and processing agent for various MGA’s, Carriers and RRG’s. Through  our network of MGA’s, Carriers and RRG’s GSIAY appoints and provides producers with  the markets they need to write their business. <br />
                                We at  GSIAY understand the commercial insurance needs of producers, independent agents and brokers and our goal is to help you succeed by providing you with  exceptional services and underwriting expertise to place your business.
                                </p> */}
                            </Grid><Grid item xs={12} style={{ height: '60vh' }}>

                                <CarouselComponent />
                            </Grid>y
                        </Grid>
                        :
                        <Fragment>
                            <Grid container style={{ float: 'left', marginTop: '52px' }}>
                                <Grid item xs={8}>
                                    <Grid container style={{ marginTop: '7%' }}>
                                        <Grid item xs={8}>
                                            <div style={{ textAlign: 'center' }}>
                                                <Fab
                                                    variant="extended"
                                                    size="medium"
                                                    color="primary"
                                                    aria-label="add"
                                                    className={classes.buttonsBap}
                                                    href="#becomeproducer"
                                                >
                                                    <AssignmentIcon className={classes.extendedIcon} />
                                                    Get Appointed
                                                </Fab>
                                                <Fab
                                                    variant="extended"
                                                    size="medium"
                                                    color="primary"
                                                    aria-label="add"
                                                    className={classes.buttonQuote}
                                                    href="#getquote"

                                                >
                                                    <DescriptionIcon className={classes.extendedIcon} />
                                                    Get a Quote
                                                </Fab>
                                                <Fab
                                                    variant="extended"
                                                    size="medium"
                                                    color="primary"
                                                    aria-label="add"
                                                    className={classes.buttonContact}
                                                    href="#contact"

                                                >
                                                    <PhoneIcon className={classes.extendedIcon} />
                                                    Contact us
                                                </Fab>

                                            </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <h2 style={{ float: 'right', color: 'white' }}>
                                                <div>
                                                    Please call us
                                                </div>

                                                <div style={{ display: 'flex' }}>
                                                <a href="tel:(706) 395-4200">(706) 395-4200</a>
                                                    
                                                </div>

                                            </h2>
                                        </Grid>
                                        {/* <Grid item xs={3}>
                                            <Card id="acc" style={{ width: '130px', margin: 20 }} onClick={cardClick}>
                                                <div style={{ paddingTop: 10, backgroundColor: '#1b507e' }}>
                                                    <PersonIcon style={{ fontSize: 30, color: '#f78320' }} />
                                                </div>
                                                <CardContent style={{ padding: 5, fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: 'bold' }}>
                                                    ACCOUNTING
                                                </CardContent>
                                            </Card>
                                        </Grid> */}
                                        {/* <Grid item xs={3}>
                                            <Card style={{ width: '150px', margin: 20 }}>
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        Lizard
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Card style={{ width: '150px', margin: 20 }}>
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        Lizard
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Card style={{ width: '150px', margin: 20 }}>
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        Lizard
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="banner-text" >
                                        <img style={{ position: 'relative', zIndex: '9000' }} alt="company logo" src={logo} width="50%"></img>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container style={{ height: '70vh', position: 'absolute', bottom: '0px', backgroundColor: '#0a2a44' }}>
                                <Grid item xs={12} style={{ backgroundColor: 'white', height: '65px' }}>
                                    <Grid container alignItems="center" alignContent="center" style={{ flexFlow: 'column' }}>
                                        <Grid item xs={12} style={{ display: 'flex' }}> 
                                            <Card className={clsx('menuCardsHover')} id="acc" onClick={cardClick} elevation={0} style={{ width: '200px' }}>
                                                <CardHeader
                                                    avatar={
                                                        <Avatar aria-label="recipe" style={{ backgroundColor: '#36af20' }}>
                                                            <AccountBalanceIcon style={{ color: 'white' }} />
                                                        </Avatar>
                                                    }
                                                    title="Accounting"
                                                    subheader="More info"
                                                />
                                            </Card>
                                            <Divider orientation="vertical" variant="middle" flexItem style={{ margin: '10px' }} />
                                            <Card className={clsx('menuCardsHover')} id="cs" elevation={0} onClick={() => window.location.href = '#contact'} style={{ width: '210px' }}>
                                                <CardHeader
                                                    avatar={
                                                        <Avatar aria-label="recipe" style={{ backgroundColor: '#36af20' }}>
                                                            <CallIcon style={{ color: 'white' }} />
                                                        </Avatar>
                                                    }
                                                    title="Customer Service"
                                                    subheader="More info"
                                                />
                                            </Card>
                                            <Divider orientation="vertical" variant="middle" flexItem style={{ margin: '10px' }} />
                                            <Card className={clsx('menuCardsHover')} id="cert" elevation={0} style={{ width: '200px' }}>

                                                <CardHeader
                                                    avatar={
                                                        <Avatar aria-label="recipe" style={{ backgroundColor: '#36af20' }}>
                                                            <InsertDriveFileIcon style={{ color: 'white' }} />
                                                        </Avatar>
                                                    }
                                                    title={<Button><Link to={process.env.PUBLIC_URL + "files/Certificates.html"} target="_blank" >Certificates</Link></Button>}
                                                
                                                >

                                                </CardHeader>
                                            </Card>
                                            <Card className={clsx('menuCardsHover')} id="cert" elevation={0} style={{ width: '220px' }}>
                                                <CardHeader
                                                    avatar={
                                                        <Avatar aria-label="recipe" style={{ backgroundColor: '#36af20' }}>
                                                            <InsertDriveFileIcon style={{ color: 'white' }} />
                                                        </Avatar>
                                                    }
                                                    title={<Button><Link to={process.env.PUBLIC_URL + "files/Endorsements.html"} target="_blank" >Endorsements</Link></Button>}
                                                    
                                                />

                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>    
                               {/*<Grid item xs={12} style={{ height: '100%'}}>
                                    <Grid container justify="center" >
                                        <Grid lg={8} >
                                            <a href="https://gsiay.epaypolicy.com/" target="_blank"> <img src={Banner}/></a>
                                        </Grid>
                                                </Grid>*/}
                                       
                                    <CarouselComponent style={{ height: '100%' }} />
                                    
                                                      
                            </Grid>
                            
                            
                        </Fragment>
                }


                {/* <p className="scrolldown">
                    <a className="smoothscroll" style={{ position: 'fixed', left: 'auto', right: '80px' }} href={currentSection} onClick={handleNextSectionClick}><i className="icon-down-circle" style={{ color: 'black' }}></i></a>
                </p> */}

            </header>
            <Dialog
                open={openAccountingDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseAccountingDialog}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Accounting"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" style={{ display: 'flex' }}>
                        <div style={{ padding: '50px' }}>
                            Accounting dept.
                            <br />
                            Tel (281) 214-3700
                            <br />
                            <a href="mailto:accounting@sternib.com">accounting@sternib.com</a>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAccountingDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </Fragment >
    );
}

export default NavBar;