import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Main from './Components/Index/Main';
import Ontrak from './Components/Ontrak';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/ontrak" component={Ontrak} />
          <Route exact path="/QQS" render={() => { window.location.href = "QQS.html" }} />
          <Route path="/" component={Main} />
        </Switch>
      </Router>
    </div >
  );
}

export default App;
